import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import 'locale/i18n';
import { CustomRouter } from 'view/router';
import PasswordProtect from 'view/subcomponents/password-protect';
import 'assets/fonts/fonts.css';
import SnackbarProvider from 'view/subcomponents/providers/snackbar-provider';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { KycThemeProvider } from 'view/subcomponents/providers/kyc-theme-provider';
import { render } from 'react-dom';

const App = () => {
	return (
		<QueryClientProvider client={new QueryClient()}>
			<ReactQueryDevtools initialIsOpen={false} />
			<KycThemeProvider>
				<PasswordProtect>
					<SnackbarProvider>
						<CustomRouter />
					</SnackbarProvider>
				</PasswordProtect>
			</KycThemeProvider>
		</QueryClientProvider>
	);
};

render(<App />, document.getElementById('root'));
