export const apiRoutes = {
	service: {
		getBackendRedirects: () => '/api/service/getBackendRedirects',
		getSystemStatus: () => '/api/service/getSystemStatus',
	},
	user: {
		getUserInfo: () => '/api/user/getUserInfo',
	},
	client: {
		getClientAddons: () => '/api/client/getClientAddons',
		getClientLogo: () => '/api/client/getClientLogo',
	},
	errand: {
		getUpdatePaymentModel: (clientId: string) =>
			`/api/errand/getUpdatePaymentModelErrand?clientId=${clientId}`,
	},
	agreement: {
		getAgreement: (agreementId: string) =>
			`/api/agreement/getAgreement?id=${agreementId}`,
	},
	statistics: {
		getClientStatisticsById: (clientId: string) => `/api/statistics/getClientStatisticsById?clientId=${clientId}`,
	},
	customerInformation: {
		getCustomerInformationWithKycId: (kycId: string) => `/api/customerInformation/getWithKycId/${kycId}`,
		getCustomerInformation: (participantId: string) => `/api/customerInformation/getForm/${participantId}`,
		getCustomerInformationForPdf: (participantId: string) => `/api/customerInformation/getResult/${participantId}`,
		getClientLogo: (participantId: string) => `/api/customerInformation/getClientLogo/${participantId}`,
		submitCustomerInformation: () => '/api/customerInformation/submit',
	},
	clientLogo: {
		getClientLogo: (clientId: string) => `/api/clientLogo/getActiveClientLogo/${clientId}`,
		getClientLogos: () => '/api/clientLogo/getActiveClientLogos',
		getNewClientLogoErrands: () => '/api/clientLogo/getNewClientLogoErrands',
	},
	mail: {
		sendMailFromAdmin: () => '/api/mail/sendMailFromAdmin',
		getMailFromAdmin: (status: string) => `/api/mail/getMailFromAdmin/${status}`,		
		getClientMailLog: (clientId: string) => `/api/mail/getClientMailLog/${clientId}`
	}
};