export enum BankIdStatuses {
	Complete = 'COMPLETE',
	Pending = 'PENDING',
}

export enum NewKycSteps {
	One = 1,
	Two = 2,
	Three = 3,
}

export enum Addons {
	Statistics = 'Statistics',
	QualifiedDecisionMaker = 'QualifiedDecisionMaker',
	Coverage = 'Coverage',
}

export enum ServiceTypes {
	Error = 'error',
	Update = 'update',
	Feedback = 'feedback',
}

export enum FormTypes {
	EstateAgent = 'Fastighetsmäklare',
	Default = 'Standard'
}

export enum RouterPaths {
	Info = '/info',
	CustomerInfo = '/customerInfo',
	Kyc = '/kyc',
	Payments = '/payments',
	Users = '/users',
	Invoices = '/invoices',
	Dashboard = '/dashboard',
	Clients = '/clients',
	Notifications = '/notifications',
	Mail ='/mail',
	Errands = '/errands',
	Agreements = '/agreements',
	Franchises = '/franchises',
	Redirects = '/redirects',
	ClientLogos = '/clientLogos',
    Countries = '/countries',
	Overview = '/översikt',
	Login = '/logga-in',
	Logout = '/logout',
	Home = '/',

}
