import LeftContainer from 'view/subcomponents/containers/left-container/left-container';
import { LeftMenuAutocomplete } from '../../subcomponents/containers/left-container/menu-items/left-menu-autocomplete';
import { useTranslation } from 'react-i18next';
import { RoleTypesEmail } from '../../../config/constants/common';

const CustomerInfoLeftContainer = () => {
	const { t } = useTranslation();

	return (
		<LeftContainer button={true}>
			<LeftMenuAutocomplete
				field={'role'}
				options={Object.values(RoleTypesEmail).map(status => ({
					value: status,
					label: t(`rolesEmail.${status}`),
				}))}
			/>
		</LeftContainer>
	);
};

export default CustomerInfoLeftContainer;
