import React, { useEffect, useState } from 'react';
import { Box, Grid, InputBase } from '@mui/material';
import { TabPanel } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useQueryContext } from 'view/subcomponents/providers/query-provider';

import AgreementCardFooter from 'view/components/agreements/subcomponents/agreement-card-footer';
import { Form } from 'view/subcomponents/form';

import { KycAutocomplete } from '../../../subcomponents/input/autocomplete/kyc-autocomplete';
import useQuery from '../../../subcomponents/hooks/use-query';
import api from '../../../../api/api';

import { useSnackbar } from 'view/subcomponents/providers/snackbar-provider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { InputEndAdornment } from '../../../subcomponents/input/end-adornment/input-end-adornment';
import { DateRangeOutlined } from '@mui/icons-material';
import RightContainerCardHeader from '../../../subcomponents/card/right-container-card/right-container-card-header';
import RightContainerCardItem from '../../../subcomponents/card/right-container-card/right-container-card-item';
import { InternalPendingButton } from '../../../subcomponents/buttons/internal-pending-button';
import { ApiResponse } from 'apisauce';
import { SnackbarTypes } from '../../../../types/snackbar';
import { isNullOrUndefined } from '../../../../utils/common';

const AgreementCardPaymentModel = ({ agreementId, clientId }) => {
	const { setValue, ...methods } = useForm({
		defaultValues: {
			paymentModel: null,
			paymentModelChangeDate: null,
		},
	});
	const { data } = useQueryContext();

	const [pending, setPending] = useState(false);
	const { data: paymentModels } = useQuery(api.getPaymentModels);
	const {
		data: updatePaymentModelErrand,
		setData,
		refetch,
	} = useQuery(api.getUpdatePaymentModelErrand, { inputData: { clientId } });
	const [datePickerOpen, setDatePickerOpen] = React.useState(false);
	const [currentPaymentModelName, setCurrentPaymentModelName] = useState('');
	const { enqueue } = useSnackbar();
	const onSubmit = async values => {
		setPending(true);

		await api.updateClientPaymentModel({ id: agreementId, data: values }).then(res => {
			if (res.ok) {
				refetch({ id: agreementId });
				enqueue(SnackbarTypes.Success, 'Agreement.Update.Success');
			} else {
				enqueue(SnackbarTypes.Error, 'Agreement.Update.Error');
			}
			setPending(false);
		});
	};

	const onLocalSubmit = values => {
		onSubmit(values).then(() => {
			refetch();
		});
	};
	const getDefaultDate = () => {
		let now = new Date();
		now.setMonth(now.getMonth() + 1);
		now.setDate(1);
		return now;
	};

	const [value, setDateValue] = useState(getDefaultDate());

	const currentMonth = new Date().getMonth();

	const onChangeDate = date => {
		const dat = new Date(date);
		dat.setHours(23, 59, 0, 0);
		setDateValue(dat);
		setDatePickerOpen(false);

		setValue('paymentModelChangeDate', {
			value: dat,
			label: 'Date',
		});
	};

	const onClick = async () => {
		await api
			.removePaymentModelErrand({
				errandId: updatePaymentModelErrand?.errandId,
			})
			.then((res: ApiResponse<any>) => {
				if (res.ok) {
					setData([]);
					enqueue(SnackbarTypes.Success, 'Errand.Success');
				} else {
					enqueue(SnackbarTypes.Error, 'Errand.Error');
				}
			});
	};

	useEffect(() => {
		if (data) {
			const { updatePaymentModelErrand, paymentModelId, paymentModelName } = data;

			setCurrentPaymentModelName(paymentModelName);

			setValue('paymentModel', {
				value: paymentModelId,
				label: paymentModelName,
			});
			setValue('paymentModelChangeDate', {
				value: value,
				label: 'Date',
			});
		}
	}, [data, setValue, setDateValue]);

	return (
		<TabPanel
			value={'4'}
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				sx={{
					fontSize: '20px',
				}}
			>
				Redigera betalningsmodell
			</Box>
			<Form methods={{ ...methods, setValue }} onSubmit={onLocalSubmit}>
				<Grid container>
					<Grid item xs={12}>
						<RightContainerCardItem sx={{ fontWeight: 'bold', mb: 1 }} title={'Nuvarande Betalningsmodell'}>
							{currentPaymentModelName}
						</RightContainerCardItem>
					</Grid>

					<KycAutocomplete
						sx={{ width: '50%' }}
						name={'newPaymentModel'}
						options={
							paymentModels?.map(value => ({
								value: value.id,
								label: value.name,
							})) ?? []
						}
						placeholder={'Ny betalningsmodell'}
						disabled={pending}
					/>

					<Grid item xs={12}>
						<RightContainerCardItem
							title={''}
							sx={{
								paddingTop: '16px',
							}}
						>
							Månad för byte :
						</RightContainerCardItem>
					</Grid>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							alignItems: 'center',
							width: '100%',
							height: '100%',
							boxSizing: 'border-box',
							padding: '0',
						}}
					>
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<DesktopDatePicker
								open={datePickerOpen}
								onOpen={() => setDatePickerOpen(true)}
								onClose={() => setDatePickerOpen(false)}
								value={value ? value.toISOString() : getDefaultDate()}
								views={['year', 'month']}
								openTo={'month'}
								mask="____-__"
								inputFormat={'YYYY-MM'}
								onChange={onChangeDate}
								renderInput={({ inputRef, inputProps: { value } }) => {
									return (
										<InputBase
											ref={inputRef}
											sx={{
												width: '25%',
												paddingLeft: '8px',
												fontWeight: 'bold',
												borderColor: 'white',
												borderRadius: 2,
												mt: 1,
											}}
											value={value}
											type="text"
											placeholder={'Datum'}
											unselectable="on"
											endAdornment={
												<InputEndAdornment
													icon={DateRangeOutlined}
													open={true}
													onClick={() => setDatePickerOpen(true)}
													sx={{
														width: '32px',
														height: '32px',
													}}
												/>
											}
										/>
									);
								}}
							/>
						</LocalizationProvider>

						{value.getMonth() == currentMonth && (
							<RightContainerCardItem sx={{ ml: 1, fontColor: 'red' }} title={''}>
								Byte av betalningsmodell på påbörjad månad får endast göras i undantagsfall och måste då stämmas av med
								ekonomi internt innan.
							</RightContainerCardItem>
						)}
					</Box>

					<AgreementCardFooter
						pending={pending}
						disabled={!isNullOrUndefined(updatePaymentModelErrand) && Object.keys(updatePaymentModelErrand).length > 0}
					/>
					{updatePaymentModelErrand && Object.keys(updatePaymentModelErrand).length > 0 && (
						<Grid item xs={6}>
							<RightContainerCardHeader>OBS! Aktivt bytesärende</RightContainerCardHeader>
							<RightContainerCardItem sx={{ fontWeight: 'bold' }} title={'Kommer att byta till'}>
								{updatePaymentModelErrand?.to}
							</RightContainerCardItem>
							<RightContainerCardItem sx={{ mt: 2, fontWeight: 'bold' }} title={' Månad som byte sker '}>
								{updatePaymentModelErrand?.dueDate}
							</RightContainerCardItem>

							<InternalPendingButton
								onClick={onClick}
								variant={'contained'}
								size={'small'}
								sx={{
									mt: 2,
									minWidth: '160px',
								}}
							>
								Ta bort ärende
							</InternalPendingButton>
						</Grid>
					)}
				</Grid>
			</Form>
		</TabPanel>
	);
};

export default AgreementCardPaymentModel;
