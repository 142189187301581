// tanstack/react-query: useQuery hook for fetching addons for this user

import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { ApiResponse } from 'apisauce';
import apiInstance from 'api/api-instance';
import headers from 'api/util/headers';
import { Agreement } from './types';
import { queryKeys } from 'store/query-keys';
import { apiRoutes } from 'store/api-routes';

type UseGetAgreementType = (id: string) => {
	query: UseQueryResult<ApiResponse<Agreement, Agreement>>;
	agreement: Agreement;
};

export const useGetUpdateAgreement: UseGetAgreementType = (id: string) => {
	const result = useQuery({
		queryKey: [queryKeys.getAgreement],
		queryFn: async () => {
			const getAgreement = (): Promise<ApiResponse<Agreement, Agreement>> =>
				apiInstance.get<Agreement>(
					apiRoutes.agreement.getAgreement(id),
					{ id },
					headers(),
				);
			const result = await getAgreement();
			return result;
		},
		refetchOnWindowFocus: false,
	});

	return {
		query: result,
		agreement: result.data?.data,
	};
};
